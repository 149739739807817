@import "styles/colors";

.dropdown {
  color: $green-500;

  label {
    color: $green-500 !important;
  }

  .MuiInputBase-root {
    color: $green-500 !important;

    &:before {
      border-bottom: 1px solid $green-500 !important;
    }

    &:after {
      border-bottom: 1px solid $green-600 !important;
    }
  }

  .MuiInputBase-root.Mui-disabled {
    &:before {
      border: none !important;
    }

    svg {
      display: none !important;
    }
  }
}


.MuiPaper-root {
  border-radius: 10px !important;
  background-color: $yellow-300 !important;
  box-shadow: 0px 3px 5px 0px rgba(201, 135, 107, 0.10) !important;

  .MuiButtonBase-root {
    color: $green-500;
  }

  .MuiSvgIcon-root {
    color: $green-600;
  }

  .MuiCheckbox-root {
    padding: 10px;
    padding-left: 0;
    padding-right: 8px;
    opacity: unset;

    .MuiTouchRipple-root {
      display: none;
    }
  }

  .Mui-selected {
    color: $green-500;
    background-color: unset !important;
    opacity: unset;
  }
}

.dropdown-select-multiple {
  .MuiButtonBase-root {
    padding: 0;
  }
}