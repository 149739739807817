@import 'src/styles/colors.scss';
@import "src/styles/variables.scss";

.btn,
%btn {
  border: 2px solid transparent;
  border-radius: 8px;
  color: $white-color;
  cursor: pointer;
  font-weight: 700;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.28s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &.primary {
    padding: 15px;
    border-radius: 44px;
    font-family: $primary-font-regular;
    font-size: $fs-16;
    font-weight: $semi-bold;
    text-transform: unset;

    &.primaryColor {
      background: $red-500;
      color: $primary-color;

      &:hover {
        background: $red-600;
      }

      &:active {
        background: $red-700;
      }
    }

    &.secondaryColor {
      border-color: $neutral3-color;
      color: $neutral3-color;
    }

    &.tertiaryColor {
      border-color: $yellow-300;
      color: $yellow-300;
    }

    &.customColor {
    }
  }

  &.secondary,
  %secondary {
    border-radius: 44px;
    padding: 15px 0;
    border: 1px solid;
    font-family: $primary-font-regular;
    font-size: $fs-16;
    font-weight: $semi-bold;
    text-transform: unset;
    background: transparent;

    &.primaryColor {
      border-color: $red-500;
      color: $red-500;

      &:hover {
        background: $red-500;
        color: $yellow-300;
      }
    }

    &.secondaryColor {
      border-color: $neutral3-color;
      color: $neutral3-color;

      &:hover {
        background: $neutral3-color;
        color: $yellow-300;
      }
    }

    &.tertiaryColor {
      border-color: $yellow-300;
      color: $yellow-300;

      &:hover {
        background: $yellow-300;
        color: $green-500;
      }
    }

    &.customColor {
    }
  }

  &.plain {
    background: none;
    border: 0;
    cursor: pointer;
    font: inherit;
    margin: 0;
    outline: inherit;
    padding: 0;
    text-transform: none;
    border-radius: 0;
  }

  &.disabled {
    opacity: 0.5;

    &:hover {
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.image {
    display: inline-block;
    background: none;
    border: 0;
    cursor: pointer;
    font: inherit;
    margin: 0;
    padding: 0;
  }
}
