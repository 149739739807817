@import "styles/variables";
@import "styles/colors";
@import "styles/breakpoints";

.completed-order-popup {
  .MuiPaper-root {
    position: relative;
    background: $primary-color url("/assets/images/completed-order-popup/pop-up-bg-order-completed.png") no-repeat center center;
    background-size: auto;
    max-width: 740px;
    max-height: 430px;
    width: 100%;
    height: 100%;

    @include breakpoint(md) {
      background-size: 105% 105%;
    }
  }

  &-title {
    font-size: $fs-31;
    color: $green-700;
  }

  &-description {
    font-size: $fs-16;
    color: $green-600;
    max-width: 375px;
    width: 100%;
    text-align: center;
    margin: auto;
  }

  &-button {
    margin: auto;
    width: 100%;
    margin-top: 24px;
    max-width: 240px;
  }
}
