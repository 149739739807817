@import "styles/colors.scss";
@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.pagination {
  .MuiPagination-ul {
    gap: 25px;

    @include breakpoint(xs) {
      gap: 32px;
    }

    @include breakpoint(sm) {
      gap: 16px;
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 10px;
      font-size: $fs-18;
    }

    .MuiButtonBase-root {
      padding: 0;
      margin: 0;
      font-size: $fs-18;
      color: $green-500;
      font-family: $primary-font-medium;
      font-weight: $medium;
      border-radius: 0;
      min-width: unset;
      height: unset;

      &:hover {
        background-color: unset;
        color: $green-700;
        font-weight: $bold;
      }

      &.Mui-selected {
        color: $green-700;
        font-weight: $bold;
        background-color: unset;
      }
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }
}