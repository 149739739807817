@import "styles/colors";
@import "styles/variables";
@import "styles/breakpoints";

.cart {
  background: $bg-login;

  &-empty {
    display: flex;
    align-items: center;
    justify-content: center;

    &-title {
      padding: 8px;
      border-radius: 4px;
      color: $white-color;
      background: $neutral1-color;
      font-size: $fs-20;
      font-weight: $bold;

      @include breakpoint(sm) {
        font-size: $fs-24;
      }
    }
  }

  &.page-layout-content {
    padding: 35px 16px;

    @include breakpoint(xl) {
      padding: 50px;
    }

    @include breakpoint(xxl) {
      padding: 80px;
    }
  }

  &-content {
    display: grid;
    grid-template-columns: 1fr;

    @include breakpoint(ml) {
      grid-template-columns: repeat(2, minmax(350px, 550px));
      gap: 15px;
    }

    @include breakpoint(lg) {
      gap: 45px;
    }
  }

  &-items {
    padding: 24px 30px;

    .cart-item {
      padding-bottom: 24px;
      border-bottom: 1px solid rgba($secondary-color, 0.2);

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  .cart-delivery-options-form {
    gap: 22px;
  }

  .cart-delivery-options {
    order: 2;
    margin-top: 14px;

    @include breakpoint(ml) {
      order: unset;
      margin-top: 0;
    }
  }

  .cart-invoice {
    .MuiFormControl-root {
      width: 100%;
      margin-left: 20px;
    }

    .MuiFormGroup-root {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 80px;
    }
  }

  .cart-invoice-fields {
    display: flex;
    flex-flow: column;
    gap: 16px;
    margin-top: 14px;
  }

  .cart-delivery {
    gap: 16px;

    .cart-total-row {
      font-size: $fs-14;
      color: $green-600;
    }

    .cart-total-price {
      font-size: $fs-24;
      color: $green-700;
      font-weight: $semi-bold;
      margin-bottom: 24px;
    }

    .separator {
      border-top: 1px solid rgba($secondary-color, 0.2);
    }
  }

  .invoice-checkbox {
    gap: 8px;

    input {
      height: 16px;
    }

    span {
      font-size: $fs-14;
      font-weight: $medium;
      color: $green-600;
    }
  }

  .section-card-header-title {
    color: $green-700;
  }
}