@import "styles/colors";
@import "styles/breakpoints";

.review-popup {
  .MuiPaper-root {
    background: $yellow-500 !important;
    max-width: 970px;
    max-height: 550px;
    width: 100%;
    padding: 24px 20px;

    @include breakpoint(sm) {
      padding: 30px 40px;
      max-height: 500px;
    }
  }

  .popup-title {
    margin: 0;
    margin-right: auto;
  }

  .popup-scrollable-content {
    width: 100%;
    flex: 1;
  }

  .popup-content-children {
    height: 100%;
  }

  .popup-content {
    max-width: 100%;
    width: 100%;
    justify-content: flex-start;
    max-height: 350px;
  }

  .popup-content-children {
    margin-top: 0;
  }

  .load-more-button {
    padding: 10px 15px;
    width: fit-content;
    margin: auto;
  }

  .review-card-wrapper {
    width: 100%;
    border-top: 1px solid rgba($secondary-color, 0.3);
    padding-top: 24px;
    padding-bottom: 16px;

    &:first-child {
      border-top: unset;
    }
  }
}