@import 'styles/colors';
@import 'styles/variables';
@import 'styles/breakpoints';

.mobile-menu {
  position: relative;
  width: 100%;
  background: $yellow-300;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: -30px;
    z-index: -1;
    background: url('/assets/images/header/menu-mobile-wave.png') no-repeat center bottom;
    background-size: contain;

    @include breakpoint(xs) {
      bottom: -45px;
    }
  }

  &-nav {
    position: relative;
    gap: 32px;
  }

  &-header {
    padding: 30px;
    gap: 32px;
  }

  &-footer {
    background: $yellow-500;
    padding: 24px 0;
    gap: 32px;

    &-link {
      color: $red-500;
      font-size: $fs-16;
      font-weight: $medium;
      text-decoration: underline;
    }

    &-word {
      font-size: $fs-14;
      font-weight: $medium;
      color: $red-500;
      opacity: 0.5;
    }

    .logout-btn {
      gap: 8px;
      color: $red-500;
      font-size: $fs-18;
      font-weight: $medium;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: $red-500;
        }
      }
    }
  }
}
