@import 'styles/colors';
@import 'styles/variables';

select {
  border: 1px solid $white-color;
  border-radius: 5px;
  margin-top: 0;
  color: $white-color !important;
  font-family: $primary-font-regular;
  font-size: $fs-16;
  line-height: 1.5;
  background: transparent;
  padding: 4px 8px 5px 8px;
  outline: none;
  background: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat;
  background-position: calc(100% - 0.5px) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}

.language-switcher {
  min-width: 160px;

  .MuiInputBase-root {
    border: 1px solid $white-color;
    border-radius: 5px;
    padding: 0 8px;
    margin-top: 0;
    color: $white-color !important;
    font-size: $fs-12;

    &:before,
    &:after {
      display: none;
    }
  }

  .MuiListItemText-root {
    margin: 0;

    .MuiListItemText-primary {
      font-size: $fs-16;
      font-family: $primary-font-regular;
    }
  }

  svg {
    fill: $white-color;
  }
}