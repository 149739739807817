@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.profile {
  display: grid;
  grid-template-columns: 1fr;
  background: $bg-login;

  @include breakpoint(ml) {
    grid-template-columns: 23% 79%;
    background: linear-gradient(to right, $primary-color 0% 23%, $bg-login 23% 100%);
  }

  @include breakpoint(xxxl) {
    background: linear-gradient(to right, $primary-color 0% 19%, $bg-login 19% 100%);
    gap: 60px;
  }

  @media (min-width: 2200px) {
    background: linear-gradient(to right, $primary-color 0% 15%, $bg-login 15% 100%);
  }

  &.page-layout-content {
    padding: 35px 16px;

    @include breakpoint(xl) {
      padding: 50px;
    }

    @include breakpoint(xxl) {
      padding: 80px;
    }
  }

  &.profile-settings,
  &.profile-delivery-and-payment {
    .profile-content {
      width: 100%;
      grid-template-columns: 1fr;

      @include breakpoint(md) {
        width: unset;
        grid-template-columns: repeat(2, minmax(0, 420px));
      }
    }
  }

  &.profile-products,
  &.profile-payments {
    .profile-content {
      @include breakpoint(ml) {
        width: 80%;
      }
    }
  }

  &-content {
    width: 100%;
    padding-left: 0;
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    gap: 14px;

    @include breakpoint(ml) {
      width: unset;
      padding-left: 50px;
    }
  }

  &-section {
    &-form {
      gap: 32px;

      &-submit-button {
        max-width: 125px;
        width: 100%;
      }

      &-title {
        font-size: $fs-20;
        color: $green-500;
        font-weight: $semi-bold;
      }
    }
  }
}