@import "styles/colors";
@import "styles/variables";

.label {
  font-size: $fs-12 !important;
  color: $yellow-500 !important;
  text-transform: uppercase;
  padding: 7px 18px;
  border-radius: 8px;
  opacity: unset !important;

  &.primary {
    background: $red-500;
  }

  &.secondary {
    background: $tertiary-color;
  }
}