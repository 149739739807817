.confirm-email-popup {
  .MuiPaper-root {
    overflow: hidden;
  }
  
  .popup-title {
    margin-left: auto;
  }

  .popup-content-text {
    text-align: center;
  }
}
