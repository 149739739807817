@import "styles/colors";
@import "styles/variables";
@import "styles/breakpoints";

.paid-ad-card {
  background: $bg-login;
  padding: 25px 20px;
  color: $green-500;
  border-radius: 10px;

  &.selected {
    border: 3px solid $red-600;

    .paid-ad-card-button {
      background: $red-700;
    }
  }

  svg {
    height: 100px;
    margin-bottom: 40px;
  }

  &-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: $fs-18;
    margin-bottom: 25px;
    max-width: 240px;

    @include breakpoint(xl) {
      height: 110px;
    }
  }

  &-price {
    font-size: $fs-31;
    font-weight: $semi-bold;
  }

  &-duration {
    margin-bottom: 24px;

    &-text {
      font-size: $fs-12;
    }

    &-days {
      font-size: $fs-16;
    }
  }

  &-button {
    margin-top: 24px;
    width: 100%;
  }
}