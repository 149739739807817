@import "colors";
@import "variables";
@import "reset";
@import "breakpoints";

@each $style, $weight in $primary-font-types {
  $class-suffix: if($style == 'Regular', '', '-' + to-lower-case($style));

  .primary-font#{$class-suffix} {
    font-family: #{$primary-font}-#{$style}, sans-serif;
  }
}

* {
  box-sizing: border-box;
}


#root,
html,
body {
  height: 100%;
  width: 100%;
  font-family: $primary-font-regular, sans-serif;
  overflow-x: hidden;
}

.fs-12 {
  font-size: $fs-12;
}

.fs-14 {
  font-size: $fs-14;
}

.fs-16 {
  font-size: $fs-16;
}

.fs-18 {
  font-size: $fs-18;
}

.fs-24 {
  font-size: $fs-24;
}

.fs-31 {
  font-size: $fs-31;
}

.light {
  font-weight: $light;
}

.regular {
  font-weight: $regular;
}

.medium {
  font-weight: $medium;
}

.semi-bold {
  font-weight: $semi-bold;
}

.bold {
  font-weight: $bold;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-underline {
  text-decoration: underline;
}

.truncate-multi-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 3.8em;

  @include breakpoint(sm) {
    max-height: 2.8em;
  }
}

.full-width {
  max-width: 100%;
  width: 100%;
}

.section-padding {
  padding: $padding-mobile;

  @include breakpoint(sm) {
    padding: $padding-tablet;
  }

  @include breakpoint(md) {
    padding: 60px;
  }

  @include breakpoint(lg) {
    padding: $padding-desktop;
  }
}

.top-section-padding {
  padding: 0 $padding-mobile;

  @include breakpoint(sm) {
    padding: 0 $padding-tablet;
  }

  @include breakpoint(md) {
    padding: 0 60px;
  }

  @include breakpoint(lg) {
    padding: 0 $padding-desktop;
  }
}

:root {
  color-scheme: light only !important;
}

/* Firefox */
@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: light only !important;
  }
}

/* Chrome */
@media (forced-colors: active) {
  :root {
    color-scheme: light only !important;
  }
}
.home-products-content-title{
display: none!important;
}
