@import "styles/colors";

.photo-uploader {
  position: relative;
  background: $secondary-color;
  border-radius: 13px;
  width: 85px;
  height: 85px;

  div:first-child {
    height: 85px;
  }

  &-preview {
    position: relative;

    & &-remove-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: $primary-color;
      border-radius: 50%;
      z-index: 10;
    }
  }

  &-image {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 13px;
    object-fit: cover;
  }

  &-icon {
    svg {
      width: 30px;
      height: 30px;
      fill: $tertiary-color;
    }

    path {
      stroke: $tertiary-color;
    }
  }
}
