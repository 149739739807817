@import "styles/colors";
@import "styles/variables";
@import "styles/breakpoints";

.product {
  background: $bg-login;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  gap: 50px;

  &.page-layout-content {
    padding: 35px 16px 50px;

    @include breakpoint(lg) {
      padding: 70px 80px;
    }
  }

  @include breakpoint(ml) {
    grid-template-columns: repeat(2, 1fr);
  }

  &-column {
    align-items: center;

    @include breakpoint(md) {
      align-items: flex-start;
    }
  }

  & &-breadcrumb {
    align-self: flex-start;
    margin-bottom: 16px;
    color: $green-600;
  }

  &-image {
    max-width: 635px;
    width: 100%;
    gap: 14px;

    &-main {
      max-height: 670px;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      object-fit: cover;
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 14px;

      @include breakpoint(xl) {
        grid-template-columns: repeat(3, minmax(200px, 1fr));
      }

      &-image {
        max-width: 200px;
        max-height: 200px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &-content {
    max-width: 100%;
    width: 100%;
    gap: 14px;

    @include breakpoint(lg) {
      max-width: 525px;
    }

    &-title {
      font-size: $fs-24;
      color: $green-700;

      @include breakpoint(sm) {
        font-size: $fs-31;
      }
    }

    &-label {
      margin-bottom: 10px;
      max-width: fit-content;
    }

    &-card {
      display: flex;
      flex-flow: column;
      background: $primary-color;
      border-radius: 20px;
      color: $green-500;
      font-size: $fs-16;

      &.details {
        gap: 32px;
        padding: 24px 16px;

        @include breakpoint(sm) {
          padding: 40px;
        }

        .description {
          font-size: $fs-14;

          @include breakpoint(sm) {
            font-size: $fs-16;
          }
        }

        .size-dropdown {
          width: 100%;

          @include breakpoint(sm) {
            max-width: 195px;
          }
        }

        .price {
          font-size: $fs-31;
          color: $green-600;
          font-weight: $semi-bold;
        }

        .add-to-cart-button {
          max-width: 365px;
          width: 100%;
        }

        .favourite-icon {
          cursor: pointer;

          &.favourite {
            path {
              fill: $red-600;
            }
          }
        }
      }

      &.seller {
        padding: 20px 14px;

        @include breakpoint(sm) {
          padding: 20px 32px;
        }

        .seller-avatar {
          width: 60px;
          height: 60px;
          border: 2px solid $green-600;
          border-radius: 60px;
          object-fit: cover;
        }

        .seller-text {
          font-size: $fs-12;
          color: $green-300;
        }

        .seller-name {
          font-size: $fs-18;
          color: $green-600;
        }

        .seller-rating-stars {
          svg {
            width: 20px;
            height: 20px;
          }
        }

        .rating {
          font-size: $fs-18;
          font-weight: $medium;
          color: $green-600;
        }
      }

      &.reviews {
        padding: 20px 16px;

        @include breakpoint(sm) {
          padding: 20px 32px;
        }

        .reviews-title {
          font-size: $fs-20;
          font-weight: $semi-bold;
          color: $green-700;
          margin-bottom: 24px;

          @include breakpoint(sm) {
            font-size: $fs-24;
          }
        }

        .reviews-footer {
          width: 100%;
          border-top: 1px solid $secondary-color;
          padding-top: 16px;
          margin-top: 16px;
        }

        .show-more-reviews-btn {
          font-size: $fs-14;
          text-decoration: underline;
          color: $red-500;

          div:first-child {
            justify-content: flex-start;
          }
        }
      }
    }
  }

  .product-actions {
    display: flex;
    gap: 8px;

    @include breakpoint(xl) {
      gap: 0;
    }
  }

  .size-and-quantity {
    flex-flow: column;
    align-items: flex-start;
    gap: 14px;

    @include breakpoint(sm) {
      align-items: center;
      flex-flow: row;
    }
  }

  .product-actions-wrapper {
    position: fixed;
    width: 100%;
    right: 0;
    padding: 16px;
    background: $primary-color;
    bottom: 0;
    z-index: 999;

    .favourite-icon {
      cursor: pointer;

      &.favourite {
        path {
          fill: $red-600;
        }
      }
    }
  }
}
