@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.home-category-card {
  position: relative;
  background: $primary-color;
  border-radius: 6px;
  box-shadow: 3px 3px 10px 0px rgba(162, 89, 64, 0.10);
  padding: 10px 0;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-image: url('/assets/images/home/green-dots.png');
    background-size: 105% 115%;
    background-position: center;
    background-repeat: no-repeat;
  }

  @include breakpoint(sm) {
    padding: 40px 0;
    border-radius: 20px;
  }

  &-image {
    max-width: 105px;
    width: 100%;
    height: auto;

    @include breakpoint(sm) {
      max-width: 400px;
      margin: auto auto 24px;
    }
  }

  &-text {
    font-size: $fs-12;
    text-transform: uppercase;
    font-weight: $bold;
    text-shadow: 1px 1px 2px rgba(141, 141, 118, 0.30);
    margin-top: auto;
    padding: 10px 0;
    max-width: 150px;

    @include breakpoint(sm) {
      max-width: 100%;
      font-size: $fs-18;
    }

    @include breakpoint(lg) {
      font-size: $fs-24;
    }

    @include breakpoint(xl) {
      font-size: $fs-31;
    }
  }
}
