@import 'styles/variables.scss';
@import 'styles/colors.scss';

.horizontal-text-separator {
  display: flex;
  align-items: center;
  width: 100%;

  hr {
    flex-grow: 1;
    border: none;
    border-top: 1px solid rgba(140, 140, 118, 0.30);
    margin: 0 10px;
  }

  &-text {
    font-size: $fs-12;
    font-weight: $medium;
    color: $green-500;
    white-space: nowrap;
  }
}
