@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.home-blog-no-banners-bg {
  .home-blog-content {
    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 323px;
      top: -270px;
      left: 0;
      height: 100%;
      bottom: 0;
      background: url('/assets/images/home/wavy-girl.svg') no-repeat;
      z-index: -1;
      transform: scale(0.8);

      @include breakpoint(xs) {
        top: -270px;
        left: 33%;
      }

      @include breakpoint(sm) {
        top: -260px;
        left: 52%;
      }

      @include breakpoint(md) {
        top: -225px;
        left: 70%;
      }

      @include breakpoint(lg) {
        top: -185px;
        left: 73%;
        transform: unset;
      }
    }
  }
}

.home-blog-banners-bg {
  .home-blog-content {
    margin-top: 395px;

    @include breakpoint(xs) {
      margin-top: 470px;
    }

    @include breakpoint(sm) {
      margin-top: 140px;
    }

    @include breakpoint(md) {
      margin-top: 140px;
    }

    @include breakpoint(lg) {
      margin-top: 110px;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 323px;
      top: -410px;
      height: 100%;
      bottom: 0;
      background: url('/assets/images/home/wavy-girl.svg') no-repeat;
      z-index: -1;
      transform: scale(0.7);

      @media (min-width: 350px) {
        top: -388px;
      }

      @media (min-width: 400px) {
        top: -370px;
      }

      @include breakpoint(xs) {
        left: 35%;
        top: -410px;
      }

      @include breakpoint(sm) {
        top: -285px;
        left: 60%;
      }

      @include breakpoint(md) {
        top: -270px;
        left: 70%;
        transform: scale(0.8);
      }

      @include breakpoint(lg) {
        top: -270px;
        left: 74%;
      }

      @include breakpoint(xl) {
        top: -225px;
        transform: unset;
      }
    }
  }
}

.home-blog {
  position: relative;

  &.section-padding {
    padding-bottom: 45px;
  }

  &-content {
    position: relative;
    z-index: 1;
    margin-top: 110px;

    @include breakpoint(sm) {
      margin-top: 55px;
    }

    @include breakpoint(md) {
      margin-top: 0;
    }

    &-title {
      font-size: $fs-28;
      font-weight: $bold;
      margin-bottom: 8px;

      @include breakpoint(sm) {
        font-size: $fs-31;
      }
    }

    &-description {
      font-size: $fs-12;
      font-weight: $medium;

      @include breakpoint(sm) {
        font-size: $fs-16;
      }
    }

    &-articles {
      &-single {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px 0;

        &>* {
          display: inline-block;
          max-width: 420px;
        }
      }

      &-grid {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: minmax(220px, 380px);
        gap: 40px;
        margin: 40px 0;

        @include breakpoint(sm) {
          gap: 14px;
          grid-template-columns: repeat(2, minmax(295px, 420px));
        }

        @include breakpoint(lg) {
          grid-template-columns: repeat(3, minmax(310px, 420px));
        }
      }
    }

    & &-button {
      padding: 15px 30px;
    }
  }
}