@import 'styles/colors.scss';
@import "styles/variables.scss";

.radio-buttons {
  .active-label,
  .non-active-label {
    font-size: $fs-16;
    color: $green-500;
  }

  .non-active-label {
    opacity: 0.5;
  }
}