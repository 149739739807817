@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.no-permissions-popup-description {
  font-size: $fs-20;
  color: $green-600;
  max-width: 375px;
  width: 100%;
  text-align: center;
  margin: auto auto 12px;
}

.create-new-product {
  display: grid;

  &-title {
    font-size: $fs-24;
    color: $neutral3-color;
    font-weight: $bold;
    margin-bottom: 36px;
    text-align: center;

    @include breakpoint(sm) {
      text-align: unset;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    gap: 16px;

    @include breakpoint(xl) {
      grid-template-columns: minmax(520px, 2fr) minmax(310px, 1fr);
      gap: 14px;
    }

    &-row {
      gap: 16px;

      &.categories-row {
        order: -1;

        @include breakpoint(xl) {
          order: 0;
        }
      }

      &.details-row {
        order: 0;

        @include breakpoint(xl) {
          order: -1;
        }
      }
    }

    button {
      order: 1;
    }

    &-section {
      display: flex;
      flex-flow: column;
      padding: 24px;
      border-radius: 20px;
      background: $yellow-500;

      &.form {
        gap: 32px;

        textarea {
          min-height: 200px;
          width: 100%;
          resize: none;
        }

        .product-status {
          margin-top: 8px;

          fieldset {
            width: 100%;
          }

          div {
            gap: 8px;
            flex-direction: row;
          }
        }
      }

      &.upload-images {
        gap: 8px;

        .upload-images-row {
          gap: 14px;
          flex-flow: row wrap;

          @include breakpoint(sm) {
            flex-flow: row nowrap;
          }
        }
      }

      &.price {
        gap: 22px;

        input::placeholder {
          text-align: end;
        }

        .price-checkbox-options {
          display: flex;
          margin-left: 5px;

          fieldset {
            width: 100%;
          }

          div {
            gap: 16px;
            flex-direction: row;
          }
        }
      }

      &.category {
        gap: 16px;
        height: fit-content;
      }

      &-title {
        font-size: $fs-16;
        color: $green-700;
        font-weight: $medium;
      }

      &-row {
        display: flex;
        flex-flow: column;
        gap: 30px;

        @include breakpoint(sm) {
          flex-flow: row;
        }

        @include breakpoint(xl) {
          align-items: center;
        }

        & &-inputs {
          display: grid;
          gap: 25px;
          align-items: flex-end;

          @include breakpoint(sm) {
            grid-template-columns: 100px 160px 100px;
            gap: 18px;
          }

          .weight {
            label {
              width: max-content;
            }
          }
        }

        & &-btn {
          max-width: 18px;
          max-height: 18px;
          padding: 0;

          svg {
            width: 18px;
            height: 18px;

            path {
              stroke: $green-700;
            }
          }
        }
      }

      .price-input {
        margin-top: 0;
        max-width: 120px;

        input {
          margin: 0;
        }

        label {
          display: none;
        }
      }
    }
  }
}