.categoriesWrapper {
  .accordionWrapper {
    margin: 10px 0;

    .accordionName {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: 10px;
      font-weight: 600;
      background-color: #e18f91;

      div {
        align-self: center;
      }
    }
  }
}

.adminButton {
  padding: 10px 15px !important;
}

.adminIcon {
  align-self: center;
  display: none !important;
}

.category-fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 15px;
}

.error-in-modal {
  color: red;
  align-self: center;
}

@media (max-width: 780px) {
  .adminButton {
    padding: 0px 15px !important;
  }
}

@media (max-width: 560px) {
  .adminButton {
    display: none;
  }

  .adminIcon {
    display: block !important;
  }
}

@media (max-width: 1255px) {
  .accordionName {
    font-size: 12px !important;
  }

  .adminButton {
    div {
      font-size: 12px !important;
    }
  }

  .categoriesWrapper {
    margin-left: -24px;
    margin-right: -24px;
  }
}
