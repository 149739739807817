@import 'src/styles/colors.scss';
@import "src/styles/variables.scss";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #fff inset !important;
}

.formControlContainer {
  width: 100%;
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-flow: column;
}

.formControl {
  font-family: $primary-font-regular;
  font-size: $fs-16;
  background: transparent;
  border: none;
  border-bottom: 1px solid $green-500;
  box-sizing: border-box;
  color: $green-500;
  position: relative;
  width: 100%;
  min-height: 27px;

  + label {
    padding: 5px 5px 10px 0;
    transition: transform 0.3s ease-in-out;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    background: transparent;
    padding: 5px 5px 10px 0;
    transform: translateX(-13%) translateY(-130%) scale(0.8);
    color: $green-500;
  }

  &[type='text'],
  &.formControlTextArea {
    border: none;
    border-bottom: 1px solid $green-500;
    color: $neutral3-color;
    min-height: 27px;
    display: block;
    font-weight: 400;
    outline: none;
    width: 100%;
  }
}

.formLabel {
  display: block;
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  pointer-events: none;
  color: $green-500;
  font-size: $fs-16;
}

.formControl::placeholder {
  opacity: 0;
}


.fileInput {
  border: 0;
  padding: 5px;
}

input::file-selector-button {
  margin-right: 10px;
}

input[type='checkbox'] {
  position: relative;
  border-radius: 3px;
  cursor: pointer;
  padding: 0;
  margin: 0;

  &::after {
    background: url('./check-white.svg') no-repeat 50%;
    background-size: 70%;
    border-radius: 2px;
    content: '';
    left: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    transition: all 0.2s ease-in-out;
    width: 17px;
    height: 17px;
  }

  &:checked {
    border-color: $green-500;

    &::after {
      background-color: $green-500;
      transform: scale(1);
    }
  }

  &:not(:checked) {
    &::after {
      background: $white-color;
    }
  }
}

.formControlSecondary {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(140, 140, 118, 0.30);
  color: $green-500;
  background: transparent;
  font-size: $fs-16;
  font-weight: $medium;
  order: 1;
}

.formLabelSecondary {
  color: $green-700;
  font-size: $fs-16;
  font-weight: $medium;
  margin-bottom: 4px;
}

.inputWithIcon {
  position: relative;
  display: flex;
  align-items: center;

  .iconContainer {
    cursor: pointer;
    position: absolute;
    right: 10px;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    background: transparent;
    padding: 5px 5px 10px 0;
    transform: translateX(-13%) translateY(-130%) scale(0.8);
    color: $green-500;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px $primary-color inset !important;
}