@import "styles/colors.scss";
@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.content-container {
  min-width: 100%;

  @include breakpoint(md) {
    min-width: 80%;
  }

  .page-seo {
    align-items: center;

    @include breakpoint(xl) {
      padding-left: 50px;
      align-items: flex-start;
    }
  }
}

.profile-products {
  &-tabs {
    @include breakpoint(sm) {
      max-width: 450px;
    }

    @include breakpoint(md) {
      max-width: 850px;
      width: 100%;
    }

    .MuiButtonBase-root {
      max-width: 200px;
      width: 100%;
    }
  }

  .MuiBox-root {
    margin-top: 40px;
    padding: 0;
  }

  .pagination {
    svg {
      width: 10px;
      height: 10px;
    }
  }

  .profile-data-grid {
    border-color: transparent;

    .MuiDataGrid-main {
      @include breakpoint(md) {
        max-width: 850px;
        width: 100%;
      }
    }

    .MuiDataGrid-columnHeaders {
      background: $table-bg;
      padding: 10px;
      border-radius: 5px;
      color: $green-600;
      margin-bottom: 8px;
      border-color: transparent;
      display: none;

      @include breakpoint(md) {
        display: flex;
      }
    }

    .MuiDataGrid-footerContainer {
      display: none;
    }


    .MuiDataGrid-columnSeparator {
      display: none;
    }

    .MuiDataGrid-columnHeaderTitleContainer {
      gap: 10px;
    }

    .MuiDataGrid-virtualScroller {
      overflow: hidden;
    }

    .MuiDataGrid-virtualScrollerRenderZone {
      overflow: hidden;
      width: 100%;

      div:first-child {
        border-radius: 5px 5px 0 0;
      }

      div:last-child {
        border-radius: 0 0 5px 5px;
      }
    }

    .MuiDataGrid-row {
      width: 100%;
      background: $yellow-500;
      padding: 16px;
      color: $green-600;
      border-bottom: 1px solid rgba(201, 135, 107, 0.20);
      max-height: unset !important;

      .MuiDataGrid-cell {
        min-height: unset !important;
        max-height: unset !important;
        border-color: transparent;
      }
    }
  }

  .profile-active-products,
  .profile-requested-products,
  .profile-complete-products {
    .pagination {
      margin-top: 40px;
      justify-content: flex-start;
    }
  }

  .products-wrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    border-radius: 20px;
    gap: 10px;

    @include breakpoint(sm) {
      background: $table-bg;
      padding: 15px;
      gap: 15px;
    }
  }
}