@import "fonts";

$fallback-font: sans-serif;

$primary-font-regular: Comfortaa-Regular, $fallback-font;
$primary-font-light: Comfortaa-Light, $fallback-font;
$primary-font-medium: Comfortaa-Medium, $fallback-font;
$primary-font-semibold: Comfortaa-SemiBold, $fallback-font;
$primary-font-bold: Comfortaa-Bold, $fallback-font;

$secondary-font: Montserrat Alternates, sans-serif;

$fs-10: 10px;
$fs-12: 12px;
$fs-14: 14px;
$fs-15: 15px;
$fs-16: 16px;
$fs-18: 18px;
$fs-20: 20px;
$fs-24: 24px;
$fs-28: 28px;
$fs-31: 31px;

$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

$padding-mobile: 16px;
$padding-tablet: 40px;
$padding-desktop: 80px;
