@import 'src/styles/colors.scss';
@import 'src/styles/variables.scss';
@import 'src/styles/breakpoints.scss';

.indexed-checkbox {
  gap: 8px;

  input {
    height: 16px;
  }

  span {
    font-size: $fs-15;
    font-weight: $medium;
    color: $green-600;
  }
}

.admin-blog-tabs {
  .css-19kzrtu {
    padding: 0;
  }

  .admin-blog-list {
    margin-top: 20px;

    &.blog {
      background: $bg-login;
    }
  }

  .admin-blog-create {
    textarea {
      min-height: 100px;
    }
  }

  .admin-blog-filter {
    width: 100%;
    gap: 25px;
    flex-flow: column;

    @include breakpoint(sm) {
      flex-flow: row;
    }

    .search-container,
    .sort-container,
    .order-container {
      max-width: 250px;
      width: 100%;
    }
  }
}

.admin-blog-edit-popup {
  .admin-blog-edit-form {
    padding-left: 12px;
    gap: 30px;

    @include breakpoint(sm) {
      padding: 10px;
    }
  }

  .popup-scrollable-content {
    width: 100%;
  }

  .MuiPaper-root {
    max-width: 750px;
  }
}

.admin-blog-publisher {
  margin-top: 20px;
}

.add-btn,
.remove-btn {
  svg {
    width: 18px;
    height: 18px;

    path {
      stroke: $green-700;
    }
  }
}

.meta-tag-group {
  display: flex;
  flex-flow: column;
  gap: 24px;
  padding: 25px 15px;

  border-bottom: 1px solid gray;

  &:last-of-type {
    border-bottom: 0;
  }
}

.admin-blog-edit {
  .photo-uploader {
    width: 250px;
    height: 250px;

    div:first-child {
      width: 250px;
      height: 250px;
    }
  }
}
