@import 'styles/breakpoints';

.products-on-focus-slider {
  .product-card {
    padding: 0;

    &:hover {
      background: unset;
      box-shadow: unset;
    }

    @include breakpoint(sm) {
      padding: 15px;

      &:hover {
        background: rgba(255, 253, 251, 0.10);
        box-shadow: 0px 0px 20px 0px rgba(124, 79, 80, 0.15);
      }
    }
  }
}
