@import "styles/colors.scss";
@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.profile-profit {
  &-table {
    &-footer {
      max-width: 850px;
      margin-top: 8px;
      width: 100%;
      gap: 20px;
      padding: 10px 34px;
      border-radius: 5px;
      background: $secondary-color;
      color: $yellow-300;

      &-text {
        font-size: $fs-12;
      }

      &-price {
        font-size: $fs-16;
      }
    }
  }
}