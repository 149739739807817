@import "styles/colors";
@import "styles/breakpoints";

.administration {
  background: $bg-login;

  &.page-layout-content {
    padding: 40px;

    @include breakpoint(sm) {
      padding: 60px;
    }

    @include breakpoint(lg) {
      padding: 80px;
    }
  }

  &-title {
    color: $green-700;
    margin-bottom: 24px;
  }

  form {
    gap: 20px;
    max-width: 550px;
    width: 100%;

    button {
      margin: auto;
    }
  }
}
