@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.page-seo-section-wrapper {
  margin-bottom: 20px;
  background: #E9BCA2;

  .page-seo-section {
    .container {
      .heading1 {
        font-size: 24px;
        color: $white-color;

        @include breakpoint(sm) {
          font-size: $fs-31;
        }
      }
    }
  }
}

.home {
  background: linear-gradient(to bottom, $secondary-color, $secondary-color 70%, $green-500);

  &-with-banners {
    background: linear-gradient(to bottom, $secondary-color, $secondary-color 70%, $yellow-500);
  }

  &.page-layout-content {
    padding: $padding-mobile 0 0;

    @include breakpoint(sm) {
      padding: $padding-desktop 0 0;
    }
  }

  .home-our-mission,
  .home-blog-wrapper {
    top: -20px;

    @include breakpoint(xl) {
      top: unset;
    }
  }

  .home-blog-wrapper {
    position: relative;

    &.home-blog {
      &-banners-bg {
        padding-top: 50px;
        background: $yellow-500;

        .home-blog-content-title,
        .home-blog-content-description,
        .card-content-title,
        .article-card-content {
          color: $green-500;
        }
      }

      &-no-banners-bg {
        background: $green-500;

        &::before {
          content: "";
          position: absolute;
          top: -24px;
          left: 0;
          right: 0;
          bottom: 0;
          background: url('/assets/images/home/wavy-green-bg.svg') no-repeat;
          background-size: 100% auto;

          @include breakpoint(sm) {
            top: -40px;
          }
        }

        .home-blog-content-title,
        .home-blog-content-description,
        .card-content-title,
        .article-card-content {
          color: $yellow-300;
        }
      }
    }
  }
}