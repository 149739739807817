@import "styles/variables";
@import "styles/colors";
@import "styles/breakpoints";

.cart-seller-conflict-popup {
  .MuiPaper-root {
    position: relative;
    background: $primary-color url("/assets/images/cart-seller-conflict-popup/pop-up-bg-single-product-page.png") no-repeat center center;
    background-size: auto;
    max-width: 740px;
    max-height: 430px;
    width: 100%;
    height: 100%;
    font-family: $primary-font-medium;

    @include breakpoint(md) {
      background-size: 105% 105%;
    }
  }

  &-title {
    font-size: $fs-31;
    font-weight: $medium;
    color: $neutral3-color;
    line-height: 140%;
    max-width: 555px;
    width: 100%;
  }

  &-description {
    font-size: $fs-16;
    color: $neutral3-color;
    max-width: 415px;
    width: 100%;
    margin: auto;
  }

  &-actions {
    gap: 16px;
    margin-top: 32px;
    flex-flow: column;

    @include breakpoint(sm) {
      flex-flow: row;
    }

    &-close {
      max-width: 140px;
      width: 100%;
    }

    &-add-to-favourites {
      max-width: 300px;
      width: 100%;
    }
  }
}