@import "styles/colors";
@import "styles/breakpoints";

.badges {
  display: flex;
  flex-flow: column;
  gap: 14px;
  margin: 0 auto;
  align-items: center;

  @include breakpoint(sm) {
    flex-flow: row;
  }

  .badge {
    cursor: pointer;
    width: 110px;
    height: 110px;
    padding: 10px 0;
    border-radius: 10px;
    background: $primary-color;

    &.selected {
      border: 2px solid $red-500;
    }
  }
}
