@import 'styles/variables';
@import 'styles/colors';
@import 'styles/breakpoints';

.article-card {
  padding: 0;
  // width: 100px;

  @include breakpoint(sm) {
    padding: 15px;
  }

  &:hover {
    background: unset;
    box-shadow: unset;

    @include breakpoint(sm) {
      background: rgba(255, 253, 251, 0.10);
      box-shadow: 0px 0px 20px 0px rgba(124, 79, 80, 0.15);
    }
  }

  .card-image {
    margin-bottom: 16px;
    max-height: 280px;
    object-fit: cover;
    max-width: 100%;

    @include breakpoint(sm) {
      height: 280px;
    }
  }

  .card-content-title {
    font-size: $fs-20;
    font-weight: $bold;
    color: $green-500;
    line-height: 28px;
  }

  &-content {
    font-size: $fs-14;
    font-weight: $medium;
    color: $green-500;
    line-height: 21px;
  }
  
  .actions {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;

    @include breakpoint(md) {
      flex-direction: row;
      gap: 5px;
    }
  }

  & &-action {
    font-size: 13px;
    padding: 15px;
    width: 100%;

    @include breakpoint(sm) {
      font-size: 11px;
      min-width: 70px;
    }
  }
}
