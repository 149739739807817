@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.login {
  display: grid;
  grid-template-columns: 1fr;
  background: linear-gradient(to bottom, $primary-color 0% 70%, $bg-login 70% 100%);

  @include breakpoint(sm) {
    grid-template-columns: 58% 42%;
    background: linear-gradient(to right, $primary-color 0% 58%, $bg-login 58% 100%);
  }

  &.page-layout-content {
    padding: 35px 27px 64px 27px;

    @include breakpoint(sm) {
      padding: 80px 0 130px;
    }
  }

  &-content {
    width: 100%;
    margin: auto;
    max-width: 400px;
    padding-bottom: 54px;

    @include breakpoint(sm) {
      max-width: 300px;
      padding-bottom: 0;
    }

    @include breakpoint(md) {
      max-width: 400px;
    }

    &-title {
      font-size: $fs-24;
      font-weight: $bold;
      color: $neutral3-color;
      margin-bottom: 36px;
    }

    &-login-links {
      width: 100%;
      gap: 16px;
    }

    &-separator {
      margin: 40px 0;
    }

    &-form {
      width: 100%;
    }

    .login-form {
      width: 100%;
      gap: 26px;

      &-control-label {
        font-size: $fs-16;
        font-weight: $medium;
        color: $neutral3-color;
      }

      &-checkbox-label {
        font-size: $fs-12;
        font-weight: $medium;
        color: $neutral3-color;
        margin-left: 8px;
      }

      &-forgot-password-link {
        margin-top: 30px;
        font-size: $fs-12;
        font-weight: $semi-bold;
        color: $neutral3-color;

        @include breakpoint(sm) {
          margin-top: 34px;
        }
      }

      &-submit-button {
        max-width: 200px;
        width: 100%;
        margin: 4px auto auto;
      }
    }
  }

  &-no-account {
    max-width: 400px;
    width: 100%;
    margin: auto;
    padding-top: 10px;

    @include breakpoint(xxs) {
      max-width: 250px;
      padding-top: 15px;
    }

    @include breakpoint(xs) {
      max-width: 400px;
      padding-top: 50px;
    }

    &-link {
      max-width: 320px;
      width: 100%;
    }

    &-title {
      font-size: $fs-24;
      font-weight: $bold;
      color: $neutral3-color;
      margin-bottom: 36px;
      text-align: center;
    }

    &-benefits {
      margin-top: 40px;
      color: $neutral3-color;

      &-title {
        font-size: $fs-16;
        font-weight: $medium;
        margin-bottom: 32px;
      }

      &-list {
        gap: 24px;

        &-item {
          &-text {
            font-size: $fs-12;
            font-weight: $medium;
          }

          svg {
            width: 24px;
            height: 20px;
            margin-right: 16px;
          }
        }
      }
    }
  }
}
