@import 'styles/colors';
@import 'styles/variables';

.profile-menu-mobile {
  gap: 32px;

  &-header {
    padding: 30px 50px 0;

    & &-create-new-ad {
      gap: 8px;
      font-size: $fs-18;
      border-radius: 10px;
      padding: 15px 0;

      span {
        color: $yellow-300;
      }
    }
  }

  &-navigation {
    gap: 32px;
    padding: 0 50px;

    &-link {
      font-size: $fs-18;
      color: $green-500;
    }
  }

  &-footer {
    padding: 24px 0;
    background: $yellow-500;

    & &-logout {
      gap: 8px;

      span {
        font-size: $fs-18;
        font-weight: $semi-bold;
        color: $red-500;
      }

      svg {
        width: 18px;
        height: 18px;

        g {
          stroke: $red-500
        }
      }
    }
  }
}
