@import "styles/colors.scss";
@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.cookie-policy {
  background: $red-700;
  flex-flow: column;
  opacity: 0.9;
  padding: 25px;
  width: 100%;
  gap: 25px;
  z-index: 1;

  @include breakpoint(lg) {
    flex-flow: row;
    padding: 35px 80px;
    gap: 50px;
  }

  &-text {
    font-size: $fs-14;
    font-weight: $medium;
    color: $yellow-300;
    line-height: 150%;
  }

  &-actions {
    gap: 16px;

    & &-accept,
    & &-reject {
      font-size: $fs-14;
      min-height: 40px;
      padding: 10px 20px;
    }

    & &-accept {
      background: $yellow-300;
      border: unset;
      color: $red-800;

      &:hover {
        background: $yellow-500;
      }
    }

    & &-reject {
      &:hover {
        background: $yellow-300;
        color: $red-800;
      }
    }
  }
}
