@import "styles/variables.scss";
@import "styles/colors.scss";
@import "styles/breakpoints.scss";

.blog {
  background: $bg-login;

  &.page-layout-content {
    @include breakpoint(xl) {
      padding: 60px 80px;
    }
  }

  &-title {
    margin-top: 15px;
    font-size: $fs-24;
    color: $green-600;

    @include breakpoint(sm) {
      font-size: $fs-31;
    }

    @include breakpoint(lg) {
      margin-top: 0;
    }
  }

  &-content {
    margin-top: 25px;

    @include breakpoint(lg) {
      margin-top: 45px;
    }

    &-posts {
      display: grid;
      margin-bottom: 60px;
      row-gap: 32px;
      grid-template-columns: repeat(1, minmax(210px, 380px));

      @include breakpoint(sm) {
        row-gap: 14px;
        column-gap: 10px;
        margin-bottom: 54px;
        grid-template-columns: repeat(2, minmax(210px, 1fr));
      }

      @include breakpoint(lg) {
        grid-template-columns: repeat(3, minmax(210px, 1fr));
      }

      @include breakpoint(xl) {
        grid-template-columns: repeat(4, minmax(310px, 1fr));
      }

      @include breakpoint(xxl) {
        grid-template-columns: repeat(5, minmax(210px, 1fr));
      }

      .article-card {
        .card-image {
          max-height: 210px;

          @include breakpoint(sm) {
            max-height: unset;
          }
        }

        .article-card-title {
          font-size: $fs-15;

          @include breakpoint(sm) {
            font-size: $fs-20;
          }
        }

        .article-card-content {
          font-size: $fs-10;

          @include breakpoint(sm) {
            font-size: $fs-14;
          }
        }
      }
    }
  }

  .pagination {
    margin-bottom: 44px;

    @include breakpoint(md) {
      margin-bottom: 0;
    }
  }
}
