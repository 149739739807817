@import "styles/colors";
@import "styles/variables";

.create-banner-field {
  margin: 20px 0;
}

.upload-banner-error {
  margin: 15px 0;
  color: red;
}

.isVisible-checkbox {
  gap: 8px;

  input {
    height: 16px;
  }

  span {
    font-size: $fs-14;
    font-weight: $medium;
    color: $green-600;
  }
}