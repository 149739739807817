@import "styles/colors";
@import "styles/variables";

.section-card {
  padding: 32px 36px;
  border-radius: 14px;
  background: $yellow-500;
  gap: 20px;

  &-header {
    gap: 8px;
    
    &-title {
      color: $green-500;
      font-size: $fs-24;
      font-weight: $semi-bold;
    }

    &-text {
      font-size: $fs-14;
      color: $green-500;
    }
  }
}