@import "styles/colors.scss";
@import "styles/variables.scss";

.filter-menu {
  border-radius: 33px;
  background: $bg-login;
  padding: 32px;
  gap: 8px;

  &-title {
    font-size: $fs-18;
    font-weight: $medium;
    margin-bottom: 16px;
    color: $green-700;
  }

  &-name {
    &.selected {
      color: $green-700;
    }

    cursor: pointer;
    color: $green-500;
    font-weight: $bold;
    font-size: $fs-18;
  }

  .MuiCheckbox-root {
    padding: 0;
    margin-right: 8px;

    input {
      width: 12px;
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }
}

.generic-filters {
  .MuiTypography-root {
    font-size: $fs-16;
    color: $green-500;
  }

  &.genders {
    .filter-menu {
      padding: 32px 22px
    }
  }
}
