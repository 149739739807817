@import "styles/colors";
@import "styles/variables";

.review-card {
  gap: 16px;
  width: 100%;

  &-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid $green-600
  }

  &-reviewer-username {
    font-size: $fs-16;
    color: $green-600;
  }

  &-rating {
    font-size: $fs-12;
    color: $green-500;
  }

  &-comment {
    font-size: $fs-14;
    color: $green-500;
  }

  &-rating-stars {
    svg {
      width: 15px;
      height: 15px;
    }
  }
}
