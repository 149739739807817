@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.home-products {
  position: relative;
  z-index: 1;

  .section-padding {
    padding-bottom: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: url('/assets/images/home/wavy-pink-bg.svg') no-repeat;
    background-size: cover;

    @include breakpoint(sm) {
      height: 111%;
    }

    @include breakpoint(xl) {
      height: 100%;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 10%;
    background: url('/assets/images/home/wavy-pink-bg.svg') no-repeat bottom;
    background-size: cover;
    display: none;

    @include breakpoint(xl) {
      display: block;
    }
  }

  &-slider {
    display: flex;
    justify-content: center;
    padding-left: $padding-mobile;

    @include breakpoint(sm) {
      padding-left: $padding-tablet;
    }

    @include breakpoint(md) {
      padding-left: 60px;
    }

    .slick-list {
      overflow: visible;
    }

    .slider-container {
      padding-left: 84px;

      @include breakpoint(xxs) {
        padding-left: unset;
      }
    }
  }

  &-content {
    padding-top: 95px;
    margin-bottom: 26px;
    z-index: 1;

    @include breakpoint(sm) {
      padding-top: 35px;
    }

    @include breakpoint(xl) {
      padding-top: 55px;
    }

    &:after {
      content: "";
      position: absolute;
      top: -60px;
      left: -20px;
      right: 0;
      bottom: 0;
      background: url('/assets/images/home/wavy-guy.svg') no-repeat;
      transform: scale(0.7);
      width: 230px;
      height: 200px;

      @include breakpoint(md) {
        top: -45px;
        left: 35px;
        transform: unset;
      }
    }

    &-title {
      font-size: $fs-28;
      font-weight: $bold;
      color: $yellow-300;
      margin-bottom: 8px;

      @include breakpoint(sm) {
        margin-bottom: 19px;
        font-size: $fs-31;
      }
    }

    &-description {
      font-size: $fs-12;
      font-weight: $medium;
      color: $yellow-300;

      @include breakpoint(sm) {
        max-width: 980px;
        font-size: $fs-16;
      }
    }
  }

  .products-on-focus-slider {
    padding-bottom: 140px;

    @include breakpoint(sm) {
      padding-bottom: 80px;
    }
  }

  .swiper-pagination {
    position: relative;
    bottom: -50px !important;

    @include breakpoint(sm) {
      bottom: -10px !important;
    }
  }
}