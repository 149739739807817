@import "styles/colors";
@import "styles/variables";

.category-filter {
  cursor: pointer;
  margin-left: 16px;

  &.selected-category {
    font-weight: $semi-bold;
  }

  .MuiFormControlLabel-root {
    margin-left: 10px;
  }

  &-name {
    padding-left: 8px;
    font-size: $fs-16;
    color: $green-700;

    font-weight: $regular;
    &.selected-category-name {
      font-weight: $semi-bold;
    }
  }

  &-checkbox {
    &.selected-checkbox {
      span {
        font-weight: $semi-bold;
      }
    }

    span {
      font-size: $fs-16;
      color: $green-600;
    }

    .PrivateSwitchBase-input {
      width: 12px;
      height: 12px;
    }
  }
}