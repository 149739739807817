@import "styles/colors";
@import "styles/variables";

.delivery-options {
  margin-top: 24px;
  gap: 16px;
  margin-left: 20px;
  max-width: 310px;

  .small-row-gap {
    gap: 20px;
  }

  .row-gap {
    gap: 24px;
  }
}

.office-delivery {
  order: 2;
}

.address-delivery {
  margin-bottom: 32px;

  &-discount-text {
    font-size: $fs-12;
    color: $white-color;
    background: $tertiary-color;
    border-radius: 4px;
    padding: 8px;
  }
}

.address-note {
  margin-top: 16px;
}
