@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.popup {
  font-family: $primary-font-regular;
  background: rgba(44, 44, 44, 0.10);
  backdrop-filter: blur(2.5px);

  .MuiPaper-root {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primary-color;
    padding: 25px 25px 56px;
    border-radius: 20px;
    max-width: 540px;
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    gap: 8px;

    @include breakpoint(sm) {
      padding: 30px 30px 63px;
    }
  }

  &-title {
    font-family: $primary-font-regular;
    font-weight: $bold;
    font-size: $fs-20;
    color: $neutral3-color;
    margin-top: 30px;

    @include breakpoint(sm) {
      font-size: $fs-24;
    }
  }

  &-close-icon {
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-left: auto;
    order: 1;
  }

  &-scrollable-content {
    max-height: 670px;
    overflow-y: auto;
    padding-right: 15px;

    @include breakpoint(sm) {
      max-height: 400px;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: $secondary-color;
      opacity: 0.21;
      border-radius: 21px;
    }

    &::-webkit-scrollbar-thumb {
      background: $secondary-color;
      border-radius: 21px;

      &:hover {
        background: $secondary-color;
      }
    }
  }

  &-content {
    max-width: 475px;
    margin-top: 8px;

    &-text {
      font-family: $primary-font-regular;
      font-weight: $medium;
      font-size: $fs-12;
      color: $neutral3-color;
      line-height: 22.4px;

      @include breakpoint(sm) {
        font-size: $fs-16;
      }
    }

    &-children {
      width: 100%;
      margin-top: 26px;
    }
  }
}
