@import 'styles/variables.scss';
@import 'styles/colors.scss';

.reset-password-popup {
  .MuiPaper-root {
    max-width: 600px;
    width: 100%;
    background: $yellow-500 !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 20px !important;
  }

  .popup-title {
    font-size: $fs-24;
    font-weight: $semi-bold;
    color: $green-500;
    width: 100%;
    margin-bottom: 8px;
  }

  .popup-scrollable-content {
    width: 100%;
  }

  .popup-content-text {
    text-align: center;
  }

  .popup-close-icon {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  &-form {
    gap: 18px;

    div:first-child,
    div:nth-child(2) {
      max-width: 400px;
      width: 100%;
    }

    &-control-label {
      font-size: $fs-16;
      font-weight: $medium;
      color: $green-500;
    }

    &-submit-button {
      max-width: 240px;
      width: 100%;
      margin-top: 40px;
    }
  }
}
