@import "styles/colors";
@import "styles/variables";
@import "styles/breakpoints";

.notification-count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  font-size: $fs-10;
  font-weight: $bold;
  background: $yellow-500;

  @include breakpoint(sm) {
    width: 24px;
    height: 24px;
    font-size: $fs-12;
  }

  &.red {
    border: 1px solid $red-500;
    color: $red-500;
  }

  &.green {
    border: 1px solid $green-500;
    color: $green-500;
  }
}