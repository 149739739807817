@import 'styles/variables.scss';
@import 'styles/colors.scss';

.card {
  position: relative;
  padding: 15px;
  border-radius: 20px;

  &.clickable {
    cursor: pointer;
  }

  &:hover {
    background: rgba(255, 253, 251, 0.10);
    box-shadow: 0px 0px 20px 0px rgba(124, 79, 80, 0.15);

    .card-icon {
      display: block;
    }
  }

  &-icon {
    position: absolute;
    display: block;
  }

  &-image {
    border-radius: 20px;
    object-fit: cover;
  }

  &-content {
    gap: 8px;

    &-title {
      font-size: $fs-16;
      font-weight: $semi-bold;
      color: $yellow-300;
    }
  }
}
