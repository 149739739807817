@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.home-categories {
  position: relative;
  z-index: 1;
  gap: 8px;
  margin-top: 20px;

  @include breakpoint(sm) {
    gap: 15px;
    margin-top: 0;
  }

  &-card {
    max-width: 635px;

    &.boys {
      color: $neutral3-color;

      .home-category-card-image {
        max-width: 90px;

        @include breakpoint(sm) {
          max-width: 200px;
        }

        @include breakpoint(xl) {
          max-width: 325px;
        }
      }
    }

    &.girls {
      color: $accent3-color;

      .home-category-card-image {
        max-width: 100px;

        @include breakpoint(sm) {
          max-width: 200px;
        }

        @include breakpoint(xl) {
          max-width: 400px;
        }
      }
    }
  }
}