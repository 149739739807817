@import "styles/colors";
@import "styles/variables";
@import "styles/breakpoints";

.all-products-container {
  padding-top: 15px;
  background: $bg-login;
}

.all-products {
  background: $bg-login;
  gap: 12px;
  flex-flow: column;
  justify-content: unset;

  @include breakpoint(sm) {
    justify-content: center;
    flex-flow: row;
  }

  &.page-layout-content {
    padding: 35px 16px 50px;

    @include breakpoint(md) {
      padding: 50px 50px;
    }

    @include breakpoint(xl) {
      padding: 70px 80px;
    }
  }

  &-no-products {
    padding: 8px;
    border-radius: 4px;
    color: $white-color;
    background: $neutral1-color;
    font-size: $fs-20;
    font-weight: $bold;
    text-align: center;

    @include breakpoint(sm) {
      font-size: $fs-24;
    }
  }

  &-wrapper {
    justify-content: center;
    width: 100%;
    max-width: 960px;

    @include breakpoint(sm) {
      justify-content: unset;
      width: unset;
    }
  }

  &-items {
    display: grid;
    grid-template-columns:  repeat(2, minmax(150px, 250px));
    justify-content: center;
    gap: 14px;
    width: 100%;

    @include breakpoint(sm) {
      grid-template-columns: repeat(2, minmax(155px, 310px));
    }

    @include breakpoint(lg) {
      grid-template-columns: repeat(3, minmax(210px, 310px));
    }

    .product-card {
      .card-content-title,
      .product-card-price {
        color: $green-700;
      }
    }
  }

  .desktop-filter-wrapper {
    border-radius: 20px;
    background: $accent1-color;
    padding: 8px;
    padding-top: 17px;
    max-width: 300px;
    width: 100%;

    .filter-text {
      gap: 8px;
      padding-bottom: 24px;
      margin-left: 20px;

      span {
        color: $white-color;
        font-size: $fs-16;
      }
    }
  }

  .pagination {
    margin-top: 54px;
  }

  .filter-mobile {
    gap: 8px;
    padding: 17px 0;
    border-bottom: 1px solid rgba($secondary-color, 0.3);
    border-top: 1px solid rgba($secondary-color, 0.3);
    width: 100%;

    &-text {
      font-size: $fs-16;
      color: $red-600;
    }

    svg {
      margin-left: 16px;

      path {
        fill: $red-600;
      }
    }
  }

  .category-description {
    margin-top: 16px;
    font-weight: $medium;

    /* Undo reset for TinyMCE content */
    * {
      box-sizing: content-box; /* Allows typical box behavior for TinyMCE content */
    }

    /* Reset specific TinyMCE HTML tags */
    h1, h2, h3, h4, h5, h6, p, blockquote, ul, ol, li, table, th, td {
      margin: revert;
      padding: revert;
      font-size: revert;
      font-weight: revert;
      line-height: revert;
      text-align: revert;
      color: revert;
    }

    /* Allow TinyMCE's inline styles */
    * {
      all: revert;
    }
  }
}
