@import "styles/breakpoints.scss";

.orders-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;

  @include breakpoint(md) {
    flex-direction: row;
  }
}

.orders-total-amount {
  margin: 0 10px;
}

.orders-export-button {
  margin-left: 20px;
}

.orders-sort-fields {
  display: flex;
  align-items: center;
  margin: 10px 0 30px 0;
  flex-direction: column;
  gap: 15px;

  @include breakpoint(sm) {
    flex-direction: row;
  }
}