@import "styles/colors";
@import "styles/breakpoints";

.write-review-popup {
  .MuiPaper-root {
    background: $yellow-500 !important;
    width: 100%;

    @include breakpoint(sm) {
      padding: 30px 40px;
    }
  }

  .popup-title {
    margin: 0;
    margin-left: auto;
  }

  .popup-content {
    max-width: 100%;
    width: 100%;
    justify-content: flex-start;
    max-height: 350px;
  }

  .popup-scrollable-content {
    width: 100%;
  }

  .popup-content-children {
    margin-top: 0;
  }

  &-form {
    gap: 24px;
    margin-top: 35px;

    div:first-child,
    div:nth-child(2) {
      max-width: 350px;
      width: 100%;
    }
  }
}