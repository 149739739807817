@import 'styles/colors';
@import 'styles/variables';

.profile-menu {
  & &-create-new-ad {
    gap: 8px;
    font-size: $fs-18;
    border-radius: 10px;
    padding: 15px 0;
    margin: 0 24px;
  }

  & &-logout-btn {
    gap: 8px;
    font-size: $fs-18;
    font-weight: $medium;
    color: $green-500;

    svg {
      width: 14px;
      height: 14px;
      fill: $green-500;
    }
  }
}
