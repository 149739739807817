@import "styles/colors.scss";
@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.blog-post {
  background: $bg-login;

  &-wrapper {
    max-width: 875px;
    width: 100%;
    margin: auto;
    padding: 34px 0 48px;
    padding-bottom: 0;

    @include breakpoint(sm) {
      padding: 0;
    }
  }

  &-content {
    width: 100%;
    color: $green-600;
    gap: 16px;

    &-image {
      width: 100%;
      max-height: 480px;
      border-radius: 20px;
      object-fit: contain;
    }

    &-heading1,
    &-heading2,
    &-text,
    &-info {
      @include breakpoint(sm) {
        margin: 0 14px;
      }
    }

    &-heading1 {
      font-size: $fs-24;
      font-weight: $bold;
      line-height: 130%;
      margin-top: 16px;

      @include breakpoint(sm) {
        font-size: $fs-31;
        margin-top: 26px;
        line-height: 140%;
      }
    }

    &-heading2 {
      font-size: $fs-20;
      font-weight: $semi-bold;
      line-height: 130%;
      margin-top: 12px;

      @include breakpoint(sm) {
        font-size: $fs-24;
        margin-top: 22px;
        line-height: 140%;
      }
    }

    &-text {
      /* Undo reset for TinyMCE content */
      * {
        box-sizing: content-box; /* Allows typical box behavior for TinyMCE content */
      }

      /* Reset specific TinyMCE HTML tags */
      h1, h2, h3, h4, h5, h6, p, blockquote, ul, ol, li, table, th, td {
        margin: revert;
        padding: revert;
        font-size: revert;
        font-weight: revert;
        line-height: revert;
        text-align: revert;
        color: revert;
      }

      /* Allow TinyMCE's inline styles */
      * {
        all: revert;
      }
    }

    &-info {
      font-size: $fs-14;
      font-weight: $medium;
    }
  }

  &-share {
    width: 100%;
    padding: 16px 30px;
    margin-top: 16px;
    gap: 16px;
    border-top: 1px solid $secondary-color;
    border-bottom: 1px solid $secondary-color;

    &-text {
      font-size: $fs-14;
      font-weight: $medium;
      color: $tertiary-color;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &-read-more {
    max-width: 100%;
    width: 100%;
    margin-top: 64px;

    @include breakpoint(sm) {
      margin: 35px 14px 0 14px;
    }

    &-title {
      font-size: $fs-20;
      font-weight: $bold;
      color: $green-600;

      @include breakpoint(sm) {
        font-size: $fs-24;
        margin-bottom: 12px;
      }
    }

    &-articles {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;

      @include breakpoint(sm) {
        flex-flow: row;
      }

      > * {
        flex: 1;
        min-width: calc(33.333% - 10px);
      }
    }
  }
}

.blog-post-read-more-articles,
.swiper {
  .article-card {
    padding: 10px;
    border-radius: 13px;

    .card-image {
      margin-bottom: 10px;
      height: 185px;
    }

    .card-content {
      gap: 5px;
    }

    .article-card-title {
      font-size: $fs-15;

      @include breakpoint(sm) {
        font-size: $fs-20;
      }
    }

    .article-card-content {
      font-size: $fs-10;

      @include breakpoint(sm) {
        font-size: $fs-16;
      }
    }
  }
}


.blog-post-read-more-articles-slider-wrapper {
  background: $bg-login;
  padding-left: 10px;
  padding-bottom: 50px;

  .swiper {
    width: 100%;
    padding-bottom: 64px;

    .article-card {
      &:hover {
        box-shadow: unset;
        background: unset;
      }
    }
  }
}
