@import 'styles/colors.scss';
@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.tabs {
  .MuiTabs-flexContainer {
    gap: 8px;

    @media (max-width: 1005px) {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 420px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .MuiButtonBase-root {
      font-size: $fs-16;
      color: $green-500;
      border: 1px solid $green-500;
      border-radius: 72px;
      padding: 15px;
      text-transform: none;

      @media (max-width: 1005px) {
        font-size: $fs-14;
        padding: 15px;
      }

      @media (max-width: 420px) {
        font-size: $fs-12;
      }
    }

    .Mui-selected {
      background: $green-700;
      color: $bg-login !important;
    }

    .Mui-disabled {
      opacity: 0.5;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
}
