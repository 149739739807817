@import "styles/variables";
@import "styles/colors";
@import "styles/breakpoints";

.page-not-found {
  background: $bg-login;

  &.page-layout-content {
    padding: 110px 20px;

    @include breakpoint(sm) {
      padding: 120px 50px;
    }
  }

  &-content {
    max-width: 730px;
    width: 100%;
    margin: auto;
    gap: 40px;

    &-img {
      max-height: 315px;
    }

    &-text {
      font-size: $fs-16;
      font-weight: $semi-bold;
      color: $green-600;

      @include breakpoint(sm) {
        margin-bottom: 24px;
        font-size: $fs-18;
      }
    }

    & &-button {
      width: 100%;
      max-width: 400px;
      margin: auto;
      padding: 15px 0;
    }
  }
}
