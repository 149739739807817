@import 'styles/colors';
@import 'styles/variables';

.header-menu {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 10px 0 rgba(99, 99, 84, 0.12);

  &-header {
    border-radius: 10px 10px 0px 0px;
    background: $green-500;
    color: $yellow-300;
    padding: 16px;
    font-size: $fs-16;
    font-weight: $medium;
  }

  &-content {
    gap: 8px;
    background: $yellow-300;
    padding: 16px 0;

    &-navigation {
      font-size: $fs-16;
      font-weight: $medium;
      color: $green-500;

      &-link {
        padding: 8px 24px;

        &:hover {
          color: $green-700;
          background: $yellow-500;
        }
      }
    }
  }

  &-footer {
    background: $yellow-300;
    border-top: 1px solid $yellow-500;
    border-radius: 0 0 10px 10px;
    padding: 15px 16px;

    &:hover {
      color: $green-700;
      background: $yellow-500;
    }
  }
}
