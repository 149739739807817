@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';
@import 'styles/variables.scss';

.header {
  position: relative;
  background: $primary-color;
  background-size: 100% auto;
  padding: 25px 16px 10px;
  gap: 20px;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -9px;
    z-index: -1;
    background: url('/assets/images/header/header-waves-desktop.png') no-repeat center bottom;
    background-size: contain;

    @include breakpoint(xs) {
      bottom: -10px;
    }

    @include breakpoint(sm) {
      bottom: -18px;
    }

    @include breakpoint(md) {
      bottom: -20px;
    }

    @include breakpoint(lg) {
      bottom: -25px;
    }

    @include breakpoint(xl) {
      bottom: -38px;

    }
  }

  @include breakpoint(md) {
    padding: 30px 50px 10px;
  }

  @include breakpoint(lg) {
    padding: 30px 80px 10px;
  }


  &-logo-wrapper {
    gap: 24px;

    .menu-icon {
      cursor: pointer;
      transition: transform 0.3s ease;

      svg {
        width: 21px;
        height: 13px;
      }

      &.open {
        transform: rotate(90deg);
      }

      &.closed {
        transform: rotate(0deg);
      }
    }
  }

  &-logo {
    width: 100%;
    max-width: 125px;

    @include breakpoint(sm) {
      max-width: 200px;
    }

    @include breakpoint(md) {
      max-width: 250px;
    }

    @include breakpoint(lg) {
      max-width: 300px;
    }
  }

  &-nav,
  &-user-items {
    gap: 30px;

    @include breakpoint(sm) {
      gap: 15px;
    }

    @include breakpoint(md) {
      gap: 30px;
    }
  }

  &-nav {
    &-link {
      font-size: $fs-18;
      color: $green-500;

      @include breakpoint(md) {
        font-size: $fs-20;
      }

      &.active,
      &:hover {
        color: $red-500;
      }
    }
  }

  &-user-items {
    align-items: baseline;
    
    .header-icon-link {
      position: relative;

      &.profile-link {
        position: unset;
      }

      .notification-count {
        position: absolute;
        right: -10px;
        top: -8px;

        @include breakpoint(sm) {
          right: -5px;
        }
      }

      &:first-child {
        order: 1;

        @include breakpoint(sm) {
          order: unset;
        }
      }

      &:hover,
      &.active {
        cursor: pointer;

        .notification-count {
          border: 1px solid $green-500;
          color: $green-500;
        }

        &:nth-child(3),
        &:nth-child(2) {
          svg path {
            fill: $red-500;
          }
        }

        &:first-child {
          svg path {
            fill: $red-500;
          }
        }
      }
    }
  }

  .profile-menu-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 290px;
    right: 5px;
    padding-top: 16px;

    @include breakpoint(lg) {
      right: 70px;
    }
  }

  &-mobile-menu-container {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -2;
    width: 100%;
  }
}
