@import "styles/breakpoints.scss";

.products-sort-fields {
  display: flex;
  align-items: center;
  margin: 10px 0 30px 0;
  flex-direction: column;
  gap: 15px;

  @include breakpoint(sm) {
    flex-direction: row;
  }
}