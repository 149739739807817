@import 'styles/colors';
@import 'styles/breakpoints';

.swiper-slide {
  max-width: 266px;
}

.swiper-pagination {
  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background: $red-700;
    opacity: 0.5;
    transform: scale(0.7) !important;

    @include breakpoint(sm) {
      width: 10px;
      height: 10px;
    }
  }

  .swiper-pagination-bullet-active {
    width: 10px;
    height: 10px;
    background: $red-700;
    opacity: 0.9;
    transform: scale(0.9) !important;

    @include breakpoint(sm) {
      width: 16px;
      height: 16px;
    }
  }
}