@import "styles/colors";
@import "styles/variables";

.mobile-products-filter {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-flow: column;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
  background: $bg-login;

  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background: $yellow-500;

    &-title {
      color: $green-700;
      font-size: $fs-18;
      text-transform: uppercase;

      .select-all-filters-text {
        font-size: $fs-12;
        color: $green-500;
        cursor: pointer;
        text-transform: capitalize;
      }
    }
  }

  &-body {
    flex: 1;
    overflow-y: auto;
    background: $bg-login;
    max-height: calc(100dvh - 150px);

    &-category {
      position: relative;
      padding: 16px;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 93%;
        height: 1px;
        background: rgba($secondary-color, 0.3);
      }

      &-title {
        color: $green-500;
        font-size: $fs-16;
      }
    }
  }

  &-footer {
    position: absolute;
    bottom: 0;
    width: 100%;

    & &-cta {
      padding: 25px;
      background: $green-700 !important;
      color: $yellow-500;
      border-radius: unset;
      width: 100%;
    }

    .footer-close {
      gap: 16px;

      svg {
        path {
          stroke: $yellow-500;
        }
      }
    }
  }
}

.selected-subcategory {
  color: $green-700;
  font-weight: bold;
}
