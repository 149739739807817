@import "styles/breakpoints";

.MuiDataGrid-main {
  svg {
    position: absolute;
  }
}

.users-search-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  @include breakpoint(sm) {
    flex-direction: row;
  }
}

.users-sort-fields {
  display: flex;
  align-items: center;
  margin: 10px 0 30px 0;
  flex-direction: column;
  gap: 15px;

  @include breakpoint(sm) {
    flex-direction: row;
  }
}