@import 'styles/colors.scss';
@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.register {
  display: grid;
  grid-template-columns: 1fr;
  background: linear-gradient(to bottom, $primary-color 0% 28%, $bg-login 28% 100%);

  @include breakpoint(sm) {
    grid-template-columns: 42% 58%;
    background: linear-gradient(to right, $bg-login 0% 42%, $primary-color 42% 100%);
  }

  &.page-layout-content {
    padding: 70px 27px 80px 27px;

    @include breakpoint(sm) {
      padding: 80px 0 130px;
    }
  }

  &-login-redirect {
    width: 100%;
    margin: auto;
    max-width: 345px;
    gap: 36px;
    padding-bottom: 97px;

    @include breakpoint(sm) {
      max-width: 250px;
      padding-bottom: 0;
    }

    @include breakpoint(md) {
      max-width: 345px;
    }

    &-text {
      font-size: $fs-24;
      font-weight: $bold;
      color: $neutral3-color;
    }

    &-link {
      width: 100%;
    }
  }

  &-content {
    width: 100%;
    max-width: 400px;
    margin: auto;
    gap: 40px;
    padding-top: 82px;

    @include breakpoint(sm) {
      max-width: 300px;
      padding-top: 0;
    }

    @include breakpoint(md) {
      max-width: 400px;
    }

    &-login-links {
      width: 100%;
      gap: 16px;
    }

    &-form {
      &-title {
        font-size: $fs-24;
        font-weight: $bold;
        color: $neutral3-color;
        margin-bottom: 32px;
      }
    }

    .register-form {
      gap: 20px;

      .checkbox-container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 8px;
      }

      &-checkbox-label {
        font-size: $fs-12;
        font-weight: $medium;
        color: $neutral3-color;

        .link {
          margin-left: 5px;
        }
      }

      &-control-label {
        font-size: $fs-16;
        font-weight: $medium;
        color: $neutral3-color;
      }
    }
  }
}
