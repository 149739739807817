@import "styles/colors";
@import "styles/variables";
@import "styles/breakpoints";

.page-seo-section {
  padding-top: $padding-mobile;
  display: flex;
  flex-flow: column;
  align-items: center;

  @include breakpoint(md) {
    padding-top: $padding-tablet;
  }

  @include breakpoint(lg) {
    padding-top: $padding-desktop;
  }

  .container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 350px;

    @include breakpoint(md) {
      max-width: 900px;
    }

    .heading1 {
      color: $green-600;
      font-size: $fs-24;
      font-weight: $bold;
      margin-bottom: 20px;

      @include breakpoint(sm) {
        font-size: $fs-31;
      }

      @include breakpoint(lg) {
        margin-top: 0;
      }
    }
  }
}