@import 'styles/breakpoints.scss';

.page-layout {
  min-height: 100vh;
  width: 100vw;

  &.menu-opened {
    .page-layout-content,
    .footer {
      position: relative;
      filter: blur(2.5px);

      &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(44, 44, 44, 0.10);
        z-index: 1;
      }

      @include breakpoint(sm) {
        filter: unset;

        &:before {
          display: none;
        }
      }
    }
  }

  &-content {
    flex: 1;
    padding: 16px;
    z-index: 0;

    @include breakpoint(lg) {
      padding: 80px;
    }
  }

  .cookie-policy {
    position: fixed;
    bottom: 0
  }
}
