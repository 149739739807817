@import 'styles/colors.scss';
@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.footer {
  background: $red-500 url("/assets/images/footer/footer-bg-mobile.png") no-repeat left top;
  padding: 27px 16px 30px 16px;
  background-size: contain;
  color: $white-color;

  @include breakpoint(sm) {
    background: $red-500 url("/assets/images/footer/footer-bg.png") no-repeat center bottom;
    background-size: 100% auto;
  }

  @include breakpoint(md) {
    padding: 64px 80px 50px 80px;
    background-size: 100% 95%;
  }

  &-row {
    flex-flow: column wrap;
    margin-bottom: 64px;

    @include breakpoint(sm) {
      flex-flow: row;
      margin-bottom: 82px;
    }
  }

  &-content {
    gap: 85px;
    margin-bottom: 64px;

    @include breakpoint(sm) {
      gap: 105px;
    }
  }

  &-list {
    gap: 16px;

    &-category {
      font-size: $fs-16;
      font-weight: $semi-bold;
      margin-bottom: 8px;

      @include breakpoint(sm) {
        font-size: $fs-18;
      }
    }

    &-item {
      font-size: $fs-12;
      font-weight: $medium;

      @include breakpoint(sm) {
        font-size: $fs-16;
      }
    }

    a {
      color: rgba($yellow-300, 0.6);

      &:hover {
        opacity: unset;
      }
    }
  }

  &-contacts {
    gap: 16px;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row wrap;

    @include breakpoint(sm) {
      justify-content: flex-start;
      align-items: flex-end;
      flex-flow: column;
    }

    &-row {
      gap: 16px;
      align-items: flex-end;
    }

    &-icon {
      width: 26px;
      height: 26px;
      margin-right: 8px;
    }

    &-phone {
      font-family: $secondary-font;
      font-size: 24px;

      @include breakpoint(sm) {
        font-size: 21px;
      }
    }

    &-social-media {
      gap: 4px;
      margin-top: 10px;

      &-icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  &-copyright-wrapper {
    flex-flow: column;

    @include breakpoint(md) {
      flex-flow: row;
    }
  }

  &-copyright {
    text-align: center;
    margin-bottom: 16px;
    font-size: $fs-14;

    @include breakpoint(sm) {
      font-size: $fs-12;
      margin-bottom: 0;
      margin-right: 50px;
    }
  }

  &-links {
    flex-flow: row wrap;
    gap: 16px;
    justify-content: center;
    align-items: center;
    font-family: $secondary-font;
    font-size: $fs-12;

    @include breakpoint(sm) {
      gap: 40px;
    }

    a {
      color: rgba($yellow-300, 0.6);

      &:hover {
        opacity: unset;
      }
    }
  }

  .language-switcher {
    max-width: 160px;
    margin-bottom: 25px;
    margin-left: auto;

    @include breakpoint(sm) {
      max-width: 100%;
      margin: unset;
    }
  }
}
