$primary-color: #FFEADB;
$secondary-color: #E9BCA2;
$tertiary-color: #C9876B;
$accent1-color: #E3ADA1;
$accent3-color: #E78B8D;
$neutral1-color: #B4B59E;
$neutral3-color: #6E6E4D;
$white-color: #FFFFFF;
$black-color: #000000;
$bg-login: #FBDECB;
$table-bg: #F2CDB7;

$yellow-50: #fffdfb;
$yellow-100: #fff8f4;
$yellow-200: #fff5ee;
$yellow-300: #fef1e7;
$yellow-400: #feeee2;
$yellow-500: #feeadb;
$yellow-600: #e7d5c7;
$yellow-700: #b4a69b;
$yellow-800: #8c8178;
$yellow-900: #6b625c;

$red-50: #fcf4f4;
$red-100: #f6dcdd;
$red-200: #f1cbcc;
$red-300: #ebb4b5;
$red-400: #e7a5a7;
$red-500: #e18f91;
$red-600: #cd8284;
$red-700: #a06667;
$red-800: #7c4f50;
$red-900: #5f3c3d;

$green-50: #f4f4f1;
$green-100: #dbdbd5;
$green-200: #cacac0;
$green-300: #b2b2a3;
$green-400: #a3a391;
$green-500: #8c8c76;
$green-600: #7f7f6b;
$green-700: #636354;
$green-800: #4d4d41;
$green-900: #3b3b32;

$orange-50: #fcf7f5;
$orange-100: #f4e7df;
$orange-200: #efdbd0;
$orange-300: #e8cbbb;
$orange-400: #e3c1ad;
$orange-500: #dcb199;
$orange-600: #c8a18b;
$orange-700: #9c7e6d;
$orange-800: #796154;
$orange-900: #5c4a40;
