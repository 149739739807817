@import "styles/colors";
@import "styles/variables";
@import "styles/breakpoints";

.paid-ads-popup {
  .MuiPaper-root {
    max-width: 1300px;
    background: $yellow-500 !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15) !important;
    max-height: 834px;
    height: 100%;
    justify-content: flex-start;
    padding: 30px;

    @include breakpoint(ml) {
      padding: 30px 30px 40px 30px;
    }
  }

  &-promo-badges {
    margin-bottom: 40px;

    &-title {
      font-size: $fs-18;
      color: $green-500;
      font-weight: $bold;
      margin-bottom: 24px;
      
      @include breakpoint(sm) {
        font-size: $fs-24;
      }
    }

    .badge {
      background: $bg-login;
      cursor: unset;
    }
  }

  .subscription-article {
    text-decoration: underline;
    color: $red-600;
    margin: 0 5px;
  }

  .popup-header {
    justify-content: center;
  }

  .popup-title {
    font-size: $fs-24;
    color: $green-500;
    margin: 0;
  }

  .popup-content-children {
    margin-top: 40px;
  }

  .popup-content-text {
    max-width: 587px;
    width: 100%;
    text-align: center;
    font-size: $fs-16;
    color: $green-500;
    margin: auto;
    margin-top: 8px;
  }

  .popup-close-icon {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  .popup-scrollable-content {
    overflow-y: visible;
    padding-right: 0;
  }

  &-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 14px;
    margin-bottom: 40px;

    @include breakpoint(sm) {
      grid-template-columns: repeat(2, minmax(180px, 280px));
    }

    @include breakpoint(ml) {
      grid-template-columns: repeat(3, minmax(180px, 280px));
    }

    @include breakpoint(xl) {
      grid-template-columns: repeat(4, minmax(180px, 280px));
    }
  }

  &-cta {
    max-width: 220px;
    width: 100%;
    margin-bottom: 30px;

    @include breakpoint(sm) {
      margin-bottom: 40px;
    }
  }
}
