@import "styles/colors";
@import "styles/variables";
@import "styles/breakpoints";

.profile-sidebar {
  width: 21%;

  @include breakpoint(xxxl) {
    width: unset;
  }

  @include breakpoint(xl) {
    padding-right: 80px;
  }

  & &-button {
    border-radius: 15px;
    font-size: $fs-12;
    gap: 10px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &-navigation {
    &-link {
      color: $green-500;
      position: relative;
      padding: 24px 0;

      &:hover {
        color: $green-600;
      }

      &.active {
        &:before {
          content: "•";
          position: absolute;
          color: $red-500;
          left: -12px;
        }
      }

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        height: 1px;
        border-bottom: 1px solid $secondary-color;

        @include breakpoint(xl) {
          width: 110%;
          left: -15px;
        }
      }
    }
  }
}
