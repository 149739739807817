@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.home-our-mission {
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  background: $primary-color;
  gap: 35px;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 125px;
    left: -25px;
    background: url('/assets/images/home/our-mission-top-right-circle.svg') no-repeat;
    width: 125px;
    height: 200px;
    transform: scale(0.8) rotate(180deg);

    @include breakpoint(sm) {
      top: 50px;
      right: -25px;
      left: unset;
      transform: scale(0.8) rotate(0deg);
    }
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 263px;
    height: 235px;
    top: -150px;
    left: 65%;
    background: url('/assets/images/home/our-mission-top-circle.svg') no-repeat;
    transform: scale(0.7);

    @include breakpoint(sm) {
      top: -70px;
      left: 35%;
      transform: scale(0.8);
    }
  }

  &.section-padding {
    padding-bottom: 70px;

    @include breakpoint(md) {
      padding-top: 100px;
      padding-bottom: 100px
    }
  }

  @include breakpoint(md) {
    grid-template-columns: repeat(2, minmax(320px, 545px));
  }

  &-image {
    z-index: 1;
    max-width: 240px;

    @include breakpoint(sm) {
      max-width: 450px;
    }

    @include breakpoint(lg) {
      max-width: 545px;
    }
  }

  &-content {
    gap: 32px;

    &:before {
      content: "";
      position: absolute;
      top: 83%;
      right: 0;
      bottom: 0;
      left: 45%;
      background: url('/assets/images/home/our-mission-bottom-circle.svg') no-repeat;
      width: 330px;
      height: 290px;
      transform: scale(0.9);
      display: none;

      @include breakpoint(md) {
        display: flex;
      }
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: -16px;
      bottom: -30px;
      background: url('/assets/images/home/our-mission-bottom-left-circle.svg') no-repeat;
      width: 150px;
      height: 345px;
      display: none;

      @include breakpoint(md) {
        display: flex;
      }
    }

    &-title {
      font-size: $fs-28;
      font-weight: $bold;
      color: $green-600;
      z-index: 1;

      @include breakpoint(sm) {
        font-size: $fs-31;
      }
    }

    &-text {
      font-size: $fs-16;
      font-weight: $medium;
      color: $green-500;
      line-height: 22.4px;
      z-index: 1;
    }

    &-button {
      max-width: 270px;

      @include breakpoint(sm) {
        max-width: 340px;
      }

      img {
        max-width: 270px;

        @include breakpoint(sm) {
          max-width: 340px;
        }
      }
    }
  }
}