@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.product-card {
  position: relative;
  gap: 12px;
  padding: 0;
  border-radius: 10px;

  @include breakpoint(sm) {
    border-radius: 20px;
  }

  &:hover {
    background: unset;
    box-shadow: unset;

    @include breakpoint(sm) {
      background: rgba(255, 253, 251, 0.10);
      box-shadow: 0 0 20px 0 rgba(124, 79, 80, 0.15);
    }
  }

  @include breakpoint(sm) {
    padding: 15px;
  }

  &.favourite {
    .card-icon {
      path {
        fill: $red-600;
      }

      g {
        fill: $red-600
      }
    }
  }

  &-badge {
    position: absolute;
    top: 0;
    left: 0;

    @include breakpoint(xl) {
      top: 7px;
      left: 7px;
    }

    svg {
      width: 74px;
      height: 70px;

      @include breakpoint(xl) {
        width: 84px;
        height: 80px;
      }
    }
  }

  .card-image {
    max-width: 100%;
    width: 100%;
    height: auto;
    aspect-ratio: 280 / 307;

    @include breakpoint(md) {
      aspect-ratio: 280 / 307;
    }

    @include breakpoint(xl) {
      aspect-ratio: 280 / 307;
    }
  }

  .card-icon {
    display: flex;
    cursor: pointer;
    width: 32px;
    height: 32px;
    right: 10px;
    top: 10px;

    &:hover {
      path {
        stroke: $red-500;
      }

      g {
        fill: $red-500
      }
    }

    @include breakpoint(sm) {
      right: 25px;
      top: 25px;
      width: 38px;
      height: 38px;
      display: none;
    }
  }

  .card-content {
    &-title {
      font-size: $fs-12;
      font-weight: $semi-bold;
      color: $yellow-300;

      @include breakpoint(sm) {
        font-size: $fs-16;
      }
    }

    .product-card-price {
      font-size: $fs-18;
      font-weight: $bold;
      color: $yellow-300;

      @include breakpoint(sm) {
        font-size: $fs-24;
      }
    }
  }
}
