@import "styles/colors";
@import "styles/variables";
@import "styles/breakpoints";

.styled-popup {
  .MuiPaper-root {
    position: relative;
    max-width: 765px;
    max-height: 440px;
    width: 100%;
    height: 100%;
    background: url('/assets/images/badge-popup/badge-popup-bg.png') no-repeat center center;
    background-size: auto;
    background-color: $bg-login !important;
    padding: 0;
    overflow: hidden;

    @include breakpoint(md) {
      background-size: 105% auto;
    }
  }

  .popup-scrollable-content {
    padding-right: 0;
  }

  &-content {
    gap: 8px;
    max-width: 630px;
    width: 100%;

    &-title {
      font-family: $primary-font-bold;
      font-size: $fs-24;
      font-weight: $bold;
      color: $neutral3-color;
      text-align: center;
    }

    &-text {
      font-family: $primary-font-medium;
      font-size: $fs-18;
      color: $neutral3-color;
      text-align: center;
    }

    &-ctas {
      width: 100%;
      gap: 10px;
      margin-top: 32px;
      flex-direction: column-reverse;

      @include breakpoint(sm) {
        flex-direction: row;
      }
    }

    &-btn {
      max-width: 200px;
      width: 100%;
      
      @include breakpoint(sm) {
        max-width: 250px;
      }
    }
  }
}
