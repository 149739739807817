@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';
@import 'styles/variables.scss';

.home-banners {
  position: relative;
  background: $green-500;

  &::before {
    content: "";
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('/assets/images/home/wavy-green-bg.svg') no-repeat;
    background-size: cover;
    z-index: 1;
    height: 160%;

    @include breakpoint(sm) {
      height: 124%;
      top: -50px;
    }

    @include breakpoint(md) {
      height: 117%;
    }

    @include breakpoint(lg) {
      height: 110%;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 95%;
    left: 0;
    right: 0;
    height: 10%;
    background: url('/assets/images/home/wavy-green-bg.svg') no-repeat $yellow-500;
    background-size: cover;
    transform: rotate(180deg);
    display: none;
    z-index: 1;

    @include breakpoint(lg) {
      display: none;
    }
  }

  &-title {
    z-index: 1;
    font-size: $fs-28;
    font-weight: $bold;
    color: $yellow-300;
    margin-bottom: 25px;

    @include breakpoint(sm) {
      font-size: $fs-31;
    }

    @include breakpoint(xl) {
      margin-bottom: 50px;
    }
  }

  &-content {
    z-index: 1;
    position: relative;
    display: grid;
    gap: 10px;

    &.single {
      grid-template-columns: 1fr;
    }

    &.double {
      grid-template-columns: repeat(2, 1fr);
    }

    &.triple {
      grid-template-columns: repeat(2, 1fr);
    }

    &.multiple {
      grid-template-columns: repeat(2, 1fr);
    }

    &.slider {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    &-image-wrapper {
      max-width: 450px;
      max-height: 450px;
      border-radius: 20px;
      overflow: hidden;
      aspect-ratio: 350 / 350;
    }

    &-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  .pseudo-elements {
    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: -85px;
      left: 53%;
      bottom: 0;
      width: 100%;
      background: url('/assets/images/home/girls.svg') no-repeat;
      transform: scale(0.5);
      z-index: 2;

      @include breakpoint(xs) {
        transform: scale(0.6);
        top: -80px;
        left: 63%;
      }

      @include breakpoint(sm) {
        transform: scale(0.7);
        top: -105px;
        left: 70%;
      }

      @include breakpoint(md) {
        transform: scale(0.8);
        top: -80px;
        left: 80%;
      }

      @include breakpoint(lg) {
        top: -25px;
        left: 90%;
        transform: unset;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 58%;
      left: -27px;
      height: 100%;
      right: 0;
      width: 120px;
      bottom: 0;
      background: url('/assets/images/home/boys.svg') no-repeat;
      z-index: 2;
      transform: scale(0.6);

      @include breakpoint(xs) {
        top: 60%;
      }

      @include breakpoint(sm) {
        transform: scale(0.7);
        top: 74%;
      }

      @include breakpoint(md) {
        transform: scale(0.8);
        top: 78%;
      }

      @include breakpoint(xl) {
        top: 85%;
        left: -50px;
        transform: unset;
      }
    }
  }

  &-slider-wrapper {
    position: relative;
  }

  .slider-container {
    z-index: 1;
    max-width: 1065px;

    .slide {
      img {
        min-height: 100%;
        object-fit: cover;
      }
    }

    .slider-control-bottomcenter {
      ul {
        top: 40px !important;
      }

      .paging-item {
        button svg {
          fill: $green-700
        }
      }
    }
  }
}
