@import "styles/colors";
@import "styles/variables";
@import "styles/breakpoints";

.profile-product-card {
  padding: 10px 14px;
  background: $yellow-500;
  border-radius: 20px;
  flex-flow: column;
  gap: 14px;

  @include breakpoint(sm) {
    flex-flow: row;
    padding: 15px 24px 15px 15px;
  }

  &-image {
    img {
      border-radius: 10px;
      max-width: 83px;
      max-height: 86px;
      width: 100%;
      height: 100%;
      object-fit: contain;

      @include breakpoint(sm) {
        max-width: 95px;
        max-height: 95px;
      }
    }
  }

  &-content {
    flex: 1;
    gap: 10px;

    @include breakpoint(sm) {
      gap: 8px;
    }

    &-published-on {
      font-size: $fs-10;
      color: $green-500;
      opacity: 0.7;

      @include breakpoint(sm) {
        font-size: $fs-12;
      }
    }

    &-name {
      font-size: $fs-14;
      color: $green-600;
      font-weight: $medium;

      @include breakpoint(sm) {
        font-size: $fs-16;
      }
    }

    &-price {
      font-size: $fs-18;
      color: $green-600;
      font-weight: $medium;
    }

    &-actions {
      gap: 40px;

      & &-button {
        max-height: 40px;
        height: 100%;
        position: relative;

        &.active {
          &:before {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            top: 0;
            right: 34%;
            background-color: $red-500;
            border-radius: 50%;
          }
        }

        &.MuiButtonBase-root {
          gap: 8px;
          padding: 0;
          width: unset;

          &:hover {
            background-color: unset;

            .profile-product-card-content-actions-button-text {
              color: $green-700;
            }

            svg path {
              fill: $green-700;
            }
          }
        }
      }

      &-button-text {
        font-size: $fs-10;
        color: $green-500;
        gap: 8px;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }
}
