@import 'src/styles/colors.scss';
@import 'src/styles/variables.scss';

.admin-page-edit-form {
  gap: 25px;
  padding: 10px;
}

.fields-container {
  display: flex;
  flex-flow: column;

  .meta-tag-group {
    margin: 18px 14px;
    display: flex;
    flex-flow: column;
    gap: 24px;
    border-bottom: 1px solid gray;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  .add-btn,
  .remove-btn {
    svg {
      width: 18px;
      height: 18px;

      path {
        stroke: $green-700;
      }
    }
  }

  .adminButton {
    padding: 5px 10px;
  }

  .edit-page-field {
    margin: 14px 0;
  }

  .indexed-checkbox {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 14px;

    span {
      font-size: $fs-14;
      font-weight: $medium;
      color: $green-600;
    }
  }

  .error-wrapper {
    justify-self: center;
    align-self: center;
    color: red;
  }
}
