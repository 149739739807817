$primary-font: 'Comfortaa';
$font-path: '../assets/fonts/';
$primary-font-types: (
        'Light': 300,
        'Regular': 400,
        'Medium': 500,
        'SemiBold': 600,
        'Bold': 700
);

@each $name, $weight in $primary-font-types {
  @font-face {
    font-family: $primary-font + '-' + $name;
    font-weight: $weight;
    font-display: swap;
    src: url($font-path + $primary-font + '-' + $name + '.ttf') format('truetype');
  }
}

$secondary-font: 'Montserrat Alternates';
$secondary-font-types: (
        'Medium': 500,
        'SemiBold': 600,
);

@each $name, $weight in $secondary-font-types {
  @font-face {
    font-family: $secondary-font + '-' + $name;
    font-weight: $weight;
    font-display: swap;
    src: url($font-path + 'MontserratAlternates' + '-' + $name + '.ttf') format('truetype');
  }
}
