@import 'styles/variables';
@import 'styles/colors';
@import 'styles/breakpoints';

.shopping-cart-card {
  width: 100%;
  gap: 14px;
  align-items: stretch;
  flex-flow: column;

  @include breakpoint(xs) {
    flex-flow: row;
  }

  &-image {
    border-radius: 20px;
    max-width: 160px;
    max-height: 160px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-title {
    font-size: $fs-18;
    font-weight: $medium;
    color: $green-600;
  }

  &-price {
    color: $green-600;
    font-weight: $medium;
    font-size: $fs-24;
  }

  &-actions {
    gap: 32px;
    flex-wrap: wrap;

    svg {
      cursor: pointer;
    }

    .favourite-icon {
      &.heart {
        &:hover {
          fill: $red-500;
        }
      }

      path {
        stroke: $red-500;
      }

      &.favourite {
        fill: $red-500;
      }
    }
  }

  .size-text {
    font-size: $fs-14;
    color: $green-700
  }

  .size-name {
    font-size: $fs-14;
    color: $green-600;
  }

  .remove-icon {
    path {
      opacity: 0.5;
    }

    &:hover {
      path {
        opacity: unset;
      }
    }
  }
}